import React from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';

import CustomerInterface from './component/CustomerInterface';
import EmployeeView from './component/EmployeeView';

const AppRoutes = () => (
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<CustomerInterface/>} exact={true} />
			<Route path="/customer" element={<CustomerInterface/>} exact={true} />
			<Route path="/employee" element={<EmployeeView/>} exact={true} />
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	</BrowserRouter>
);

export default AppRoutes;