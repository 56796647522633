import React from 'react';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import logo from '../assets/img/ri-logo.png';
import {apiUrlAddress, apiToken} from '../config.js';

function CustomerInterface() {
	const [alert, setAlert] = useState({
		show: false, 
		variant: "danger",  
		message: ""
	});

	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({
		customer_name: '',
		customer_phone: '',
		customer_email: '',
		customer_bizname: '',
		customer_gst: '',
		// address: ''
	});

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setAlert({
				...alert,
				show: true,
				variant: 'danger',
				message: 'Please fill in all fields.'
			});
		}
		else {
			setAlert({
				...alert,
				show: false
			});
			validateForm(formData)
		}

		setValidated(true);
	};

	const submitForm = (formData) => {
		const url = apiUrlAddress + 'api/customer/add_customer?token=' + apiToken;

		fetch(url, {
			method: 'POST', // You can change this to 'GET' if appropriate
			headers: {
				'Content-Type': 'application/json', // Modify this as per your API requirements
			},
			body: JSON.stringify(formData), // Make sure to stringify your data if it's an object
		})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json(); // Parse the JSON response
		})
		.then(res => {
			// Handle the response data
			if(res && res.message) {
				setAlert({
					...alert,
					show: true,
					variant: (res.code == 1) ? 'success' : 'alert',
					message: res.message
				});
			}

			if(res.code == 1) {
				setFormData({
					customer_name: '',
					customer_phone: '',
					customer_email: '',
					customer_bizname: '',
					customer_gst: ''
				});
			}
		})
		.catch(error => {
			// Handle any errors
			console.error('There was a problem with the fetch operation:', error);
			setAlert({
				...alert,
				show: true,
				variant: 'danger',
				message: 'There was a problem with the server! Your request could not be processed. Try again after sometime.'
			});
		});
	}

	const validateForm = (formData) => {
		const url = apiUrlAddress + 'api/customer/validate_customer?token=' + apiToken;
	
		fetch(url, {
			method: 'POST', // You can change this to 'GET' if appropriate
			headers: {
				'Content-Type': 'application/json', // Modify this as per your API requirements
			},
			body: JSON.stringify(formData), // Make sure to stringify your data if it's an object
		})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json(); // Parse the JSON response
		})
		.then(res => {
			// Handle the response data
			if(res && res.message) {
				setAlert({
					...alert,
					show: true,
					variant: (res.code == 1) ? "success" : "warning",
					message: res.message
				});
			}

			if(res.code == '1') {
				submitForm(formData);
			}
		})
		.catch(error => {
			// Handle any errors
			console.error('There was a problem with the fetch operation:', error);
			setAlert({
				...alert,
				show: true,
				variant: 'danger',
				message: 'There was a problem with the server! Your request could not be processed. Try again after sometime.'
			});
		});
	};

	return(
		<div>
			<div className="container">
				<div className="row mt-4">
                    <div className="col-md-12">
                		<header>
                			<div className="header disp-flex">
                				<div className="page-logo">
                		        	<img src={logo} alt="Logo" />
                		        </div>
                		        <h1 className="pl-2">Customer Details</h1>
                	        </div>
                	    </header>
						
						{alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}

	                    <Form method="post" noValidate validated={validated} autoComplete='off' onSubmit={handleSubmit}>
	                        <Form.Group className="mb-3" controlId="customer_name">
	                            <Form.Label>Customer Name <span className="sufix required">(Required)</span>:</Form.Label>
	                            <Form.Control type="text" required placeholder="Enter fullname" name='customer_name' value={formData.customer_name} onChange={handleChange} />
								<Form.Control.Feedback type="invalid">
              						Enter the customer name.
            					</Form.Control.Feedback>
	                        </Form.Group>
	                        <Form.Group className="mb-3" controlId="customer_phone">
	                            <Form.Label>Phone <span className="sufix required">(Required)</span>:</Form.Label>
	                            <Form.Control type="text" required placeholder="Phone Number" name='customer_phone' value={formData.customer_phone} onChange={handleChange} />
								<Form.Control.Feedback type="invalid">
              						Enter your phone number.
            					</Form.Control.Feedback>
	                        </Form.Group>
	                        <Form.Group className="mb-3" controlId="customer_email">
	                            <Form.Label>Email <span className="sufix optional">(Optional)</span>:</Form.Label>
	                            <Form.Control type="text" placeholder="Email" name='customer_email' value={formData.customer_email} onChange={handleChange} />
	                        </Form.Group>
							<Form.Group className="mb-3" controlId="customer_gst">
	                            <Form.Label>GSTIN <span className="sufix optional">(Optional)</span>:</Form.Label>
	                            <Form.Control type="text" placeholder="Business GST Number" name='customer_gst' value={formData.customer_gst} onChange={handleChange} />
	                        </Form.Group>
	                        <Form.Group className="mb-3" controlId="customer_bizname">
	                            <Form.Label>Business Name <span className="sufix optional">(Optional)</span>:</Form.Label>
	                            <Form.Control type="text" placeholder="Business Name" name='customer_bizname' value={formData.customer_bizname} onChange={handleChange} />
	                        </Form.Group>
	                        {/* <Form.Group className="mb-3" controlId="address">
	                            <Form.Label>Address <span className="sufix optional">(Optional)</span>:</Form.Label>
	                            <Form.Control as="textarea" rows={3} name='address' value={formData.address} onChange={handleChange} />
	                        </Form.Group> */}
	                        <Button className="w-100" variant="primary" type="submit">
	                            Submit
	                        </Button>
	                    </Form>
                    </div>
                </div>
	           </div> 
		</div>
	);
}

export default CustomerInterface;