import React from 'react';
import logo from '../assets/img/ri-logo.png';
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function EmployeeView() {
	const rows = [];

	for(let i=1; i<=31; i++) {
		rows.push(
			<tr key={i}>
			    <td>
			    	<p className="pt-2 m-0">01</p>
			    </td>
			    <td>
			        <Form.Select>
			        	<option value="1">1/4</option>
			        	<option value="2">1/2</option>
			        	<option value="3">1</option>
			        	<option value="4">3/4</option>
			        	<option value="5">2</option>
			        </Form.Select>
			    </td>
			    <td>
			        <Form.Group className="mb-3" controlId="amount">
			            <Form.Control type="text" placeholder="Amount" />
			        </Form.Group>
			    </td>
			    <td className="text-center">
			    	<p className="pt-2 m-0">₹520</p>
			    </td>
			    <td>
			    	<Button className="w-100" variant="primary" type="submit">
			    	    Add
			    	</Button>
			    </td>
			</tr>
		);
	}
    return (
        <div className="container">
            <div className="row mt-12">
                <header>
                    <div className="header attendance-header disp-flex mt-2">
                        <div className="page-logo">
                            <img src={logo} alt="Logo" />
                        </div>
                        <h3 className="pl-2">Employee Attendance Monitoring</h3>
                    </div>
                </header>
                <div className="calendar-view">
                	<form>
	                	<div className="disp-flex justify-content-space month-view">
	                	    <button className="btn btn-primary btn-ctrl"><SlArrowLeft /></button>
	                	    <h5 className="m-0 text-white">March</h5>
	                	    <button className="btn btn-primary btn-ctrl"><SlArrowRight /></button>
	                	</div>
	                	<table className="table mt-3">
	                	    <thead>
	                	        <tr>
	                	            <th>Day</th>
	                	            <th>Daily</th>
	                	            <th>Funds Borrowed</th>
	                	            <th className="text-center">Payment Due</th>
	                	            <th></th>
	                	        </tr>
	                	    </thead>
	                	    <tbody>
	                	        {rows}
	                	        <tr>
	                	            <td colSpan="3" className="text-right"><b className="ft-18">Your balance:</b></td>
	                	            <td className="text-center"><b className="ft-18">₹5000</b></td>
	                	            <td></td>
	                	        </tr>
	                	    </tbody>
	                	</table>
                	</form>
                </div>
            </div>
        </div>
    );
}

export default EmployeeView;
